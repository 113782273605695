





















































import { Component, Vue, Prop } from 'vue-property-decorator'
import vSelect from 'vue-select'
import SelectOption from './SelectOption'

type SelectValueObg = {
  [key: string]: string | number
}

@Component({
  components: { vSelect },
  inheritAttrs: false,
  name: 'BaseSelect',
})
export default class BaseSelect extends Vue {
  @Prop({ default: false })
  required: any

  @Prop()
  errorText!: string

  @Prop({ default: false })
  hasError!: boolean

  @Prop({ default: false })
  isShakeError!: boolean

  @Prop({ default: 'Выберите значение' })
  placeholder!: string

  @Prop({ default: false })
  multiple!: boolean

  @Prop()
  value!: string | number | SelectValueObg

  @Prop()
  label!: string

  @Prop({ default: 'label' })
  labelName!: string

  @Prop({ default: '' })
  labelValue!: string

  @Prop()
  classWrap!: string

  @Prop({ default: false })
  clearable!: boolean

  @Prop({ default: false })
  searchable!: boolean

  @Prop({ required: true, default: () => [] })
  options!: SelectOption[]

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  @Prop({ default: () => {} })
  reduce!: string | number

  @Prop()
  isSimple!: boolean

  @Prop()
  isDropdownRight!: boolean

  @Prop({ default: true })
  isArrow!: boolean

  @Prop({ default: false })
  isSmall!: boolean

  @Prop({ default: false })
  group!: boolean

  rusOptionsSearch(option: unknown, label: string, search: string): boolean {
    return (
      (label || '')
        .toLowerCase()
        .replace('ё', 'е')
        .indexOf(search.toLowerCase().replace('ё', 'е')) > -1
    )
  }

  get initValue(): unknown {
    return this.value
  }

  set initValue(newVal: unknown) {
    this.$emit('input', newVal)
  }
}
